.layout-wrapper {
  height: 100vh;
  display: flex;
}

.layout-wrapper .top-menu-wrapper .avatar {
  cursor: pointer;
}

.layout-wrapper .main-content-wrapper {
  overflow-y: scroll;
}
