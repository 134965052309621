.header .top-menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header .top-menu-wrapper .logo-key.ant-menu-item-selected {
  background-color: initial;
}

.header .top-menu-wrapper .logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
