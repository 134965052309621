.filter-bar-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
  background: white;
  padding: 24px;
}

.filter-bar-wrapper .left-side-wrapper {
  display: flex;
  width: 100%;
}

.filter-bar-wrapper .left-side-wrapper .name-search,
.filter-bar-wrapper .left-side-wrapper .tag-search {
  max-width: 260px;
  margin-right: 16px;
  width: 100%;
}

.filter-bar-wrapper .left-side-wrapper .province-state-search {
  max-width: 260px;
  margin-right: 12px;
  width: 100%;
}
