.table-wrapper {
  margin: 16px auto 0;
  padding: 0 32px;
}

.table-wrapper .heading-wrapper {
  margin: 24px 0 16px;
  display: flex;
  justify-content: space-between;
}

.table-wrapper .results-count-wrapper {
  margin-bottom: 12px;
}

.table-wrapper table tbody tr:hover {
  cursor: pointer;
}
