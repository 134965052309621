.wrapper {
  margin: 16px auto 0;
  padding: 0 32px;
}

.wrapper .heading-wrapper {
  margin: 24px 0 16px;
  display: flex;
  justify-content: space-between;
}

.wrapper .content-wrapper .main-actions-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 0 32px;
}

.wrapper .content-wrapper .main-actions-wrapper .google-map-link {
  margin-right: 8px;
}

.wrapper .content-wrapper .inner-content {
  margin: 0 auto;
  max-width: 700px;
  flex-grow: 1;
}

.wrapper .content-wrapper .ant-card {
  margin-bottom: 32px;
}

.wrapper .content-wrapper .inner-content form {
  margin-top: 32px;
}

.wrapper .content-wrapper .inner-content form .buttons-wrapper {
  margin: 0 auto;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
