.login-layout {
  min-height: 100vh;
  background: #fff;
  text-align: center;
}

.login-layout h3 {
  margin-bottom: 36px;
}

.login-layout .ant-layout-content {
  margin: 10vh auto 0;
  max-width: 450px;
  width: 100%;
}
